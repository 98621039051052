<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('My courses')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('My courses')" :isFilter="false" />
            </template>
            <b-row class="ml-1">
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('semester')">
                        <semesters-select-box @input="getData()"  v-model="filter.semester_id">
                        </semesters-select-box>
                    </b-form-group>
                </b-col>
            </b-row>
<!--            <b-row class="mb-3 ml-1">-->
<!--                <b-col cols="6" md="3" lg="2" xl="1">-->
<!--                    <b-button variant="primary" class="width-full" @click="getData">-->
<!--                        {{ $t('search').toUpper() }}-->
<!--                    </b-button>-->
<!--                </b-col>-->
<!--                <b-col cols="6" md="3" lg="2" xl="1">-->


<!--                    <b-button variant="danger" class="width-full" @click="filterClear">-->
<!--                        {{ $t('clear').toUpper() }}-->
<!--                    </b-button>-->

<!--                </b-col>-->
<!--            </b-row>-->
            <div class="mb-5 align-items-center">
                <b-card-group deck>
                    <div v-for="(item, key) in courses" class="col-12 col-sm-6 col-md-6 col-lg-3 mb-4" :key="key">
                        <b-card bg-variant="light" :header="item.course_code" class="text-center cursor"
                            @click="showDetail(item.course_id)">
                            <b-card-text>{{ item.course_name }}</b-card-text>
                        </b-card>
                    </div>
                </b-card-group>
                <template v-if="courses.length < 1"><div class="text-center">
                    <p class="text-black-90">{{ $t('no_course') }}</p>
                </div> </template>
            </div>
            <CommonModal size="xl" ref="detailModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('course_detail_title').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <course-detail v-if="process == 'course_detail'" :id="selectedId" :semester_id="filter.semester_id"></course-detail>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import qs from 'qs'
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Page
import CourseDetail from "./CourseDetail";

// Component
import CommonModal from "@/components/elements/CommonModal";

// Services
import SectionService from "@/services/SectionService";
import SettingsService from "@/services/SettingsService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        CourseDetail,
        SemestersSelectBox: () => import("@/components/interactive-fields/SemestersSelectbox")
    },
    data() {
        return {
            courses: [],
            selectedId: null,
            process: null,
            filter: {}
        }
    },
    metaInfo() {
        return {
            title: this.$t("My courses")
        }
    },
    methods: {
        getSettings() {
            return  SettingsService.getSemesterSettings()
                .then(response => {
                    this.filter.semester_id = response.data.data.active_semester_id
                    this.getData()
                })
        },
        getData() {
            const config = {
                params: {
                    ...this.filter
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            SectionService.instructorCourses(config)
                .then(response => {
                    this.courses = response.data.data
                })
        },

        showDetail(courseId) {
            if (this.checkPermission('section_instructor')) {
                this.process = 'course_detail'
                this.selectedId = courseId
                this.$refs.detailModal.$refs.commonModal.show()
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        formClear() {
            this.process = null;
            this.$refs.detailModal.$refs.commonModal.hide()
        },
        filterClear() {
        this.filter = {}
        this.getData()
    },
    },
    created() {
        this.getSettings()
        this.getData()
    }
}
</script>
<style scoped>
.cursor {
    cursor: pointer;
}
</style>
